import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { history } from '../_helpers/history';
import { authAtom, apiErrorAtom, adminAtom } from '../_state/state';


export { useFetchWrapper };

function useFetchWrapper() {

    const [auth, setAuth] = useRecoilState(authAtom);
    const setApiError = useSetRecoilState(apiErrorAtom);
    const admin = useRecoilValue(adminAtom);

    return {
        get: request('GET'),
        post: request('POST'),
    };


    function request(method) {
        return (url, body) => {
            const requestOptions = {
                method,
                headers: authHeader(url)
            };
            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }

            return fetch(url, requestOptions).then(handleResponse);
        }
    }


    // helper functions
    function authHeader(url) {
        // return auth header with jwt if user is logged in and request is to the api url
        let token = null;

        if (auth != null) {
            token = auth[0].Token;
        }

        const isLoggedIn = !!token;
        //const isApiUrl = url.startsWith('http://utility5.wooddale.tempco.com');

        if (isLoggedIn) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    }


    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            console.log('data : ' + data);

            if (!response.ok) {

                if ([401, 403].includes(response.status)) {

                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    history.push('/login');
                }

                if (admin == false) {

                    console.log('Admin is false : ' + admin);
                    console.log('Fetch-Wrapper -> Response -> error: \r\n' + response.statusText + '\r\n' + text + '\r\n' + data.message + 'TEST');

                    sessionStorage.removeItem('user');
                    sessionStorage.removeItem('customer');

                    setAuth(null);
                }
                else {
                    if ([401, 403].includes(response.status)) {
                        setAuth(null);
                        sessionStorage.removeItem('user');
                        sessionStorage.removeItem('customer');
                    }
                    console.log('Admin is true ' + admin);
                }

                setApiError(response.statusText + ": " + data.message);
            }  
            return data;
        });
    }    
}
