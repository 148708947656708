import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { Nav } from './_components/Nav';
import { PrivateRoute } from './_components/PrivateRoute';
import { Footer } from './_components/Footer';
import { history } from './_helpers/history';
import { Home } from './home/Home';
import { Update } from './update/Update';
import { Login } from './login/Login';
import { Options } from './options/Options';


/*import { authAtom, adminAtom } from '../_state';*/

export default App;

function App() {
    return (
        <div className="app-container bg-light">
            <Router history={history}>
                <Nav />
                <div className="container pt-4 pb-4">
                    <Switch>
                        <PrivateRoute exact path="/" component={Home} />
                        <Route path="/login" component={Login} />
                        <PrivateRoute exact path="/update" component={Update} />
                        <PrivateRoute exact path="/options" component={Options} />
                        <Redirect from="*" to="/" />
                    </Switch>
                </div>
                <Footer />
            </Router>
        </div>
    );
}

