import { useSetRecoilState } from 'recoil';

import { useFetchWrapper } from '../_helpers/fetch-wrapper';
import { openOrdersAtom, orderRespAtom, apiErrorAtom } from '../_state/state';

export { useOpenOrdersActions };

function useOpenOrdersActions() {
    let baseUrl = 'https://ordertracker.tempco.com/ordertracker';
    let openURI = baseUrl + "/openorders2";

    const fetchWrapper = useFetchWrapper();
    const setOpenOrders = useSetRecoilState(openOrdersAtom);
    const setApiError = useSetRecoilState(apiErrorAtom);
    const setOrderRespFlag = useSetRecoilState(orderRespAtom);

    return {
        getOpenOrders
    }

    function getOpenOrders(qnumber, internal) {
        setOpenOrders([]);

        return fetchWrapper.post(openURI, { qnumber, internal } )
            .then(openorders => {

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                setOpenOrders(openorders);

            }).catch(error => {
                setOpenOrders([]);
                setOrderRespFlag(false);
                console.error('OpenOrderActions->getOpenOrders()\r\n' + error)
                setApiError('Error Retrieving Open Orders...');
            });;
    }
}
