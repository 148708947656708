import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';

import './index.css';
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <App />
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('app')
);


// //After
//import { createRoot } from 'react-dom/client';
//const container = document.getElementById('app');
//const root = createRoot(container); // createRoot(container!) if you use TypeScript
//root.render(<React.StrictMode>
//    <RecoilRoot>
//        <App />
//    </RecoilRoot>
//</React.StrictMode>);