import { useSetRecoilState, useRecoilValue } from 'recoil';

import { useFetchWrapper } from '../_helpers/fetch-wrapper';
import { closedOrdersAtom, orderRespAtom, apiErrorAtom } from '../_state/state';

export { useClosedOrdersActions };

function useClosedOrdersActions() {


    let baseUrl = 'https://ordertracker.tempco.com/ordertracker';
    let closedURI = baseUrl + "/closedorders";

    const fetchWrapper = useFetchWrapper();
    const setClosedOrders = useSetRecoilState(closedOrdersAtom);
    const setOrderRespFlag = useSetRecoilState(orderRespAtom);
    const setApiError = useSetRecoilState(apiErrorAtom);

    return {
        getClosedOrders
    }

    function getClosedOrders(qnumber, internal) {
        setClosedOrders([]);

        return fetchWrapper.post(closedURI, { qnumber, internal })
            .then(closedorders => {

                setClosedOrders(closedorders);
                setOrderRespFlag(true);
            }).catch(error => {

                setOrderRespFlag(false);
                setClosedOrders([]);
                console.log('ClosedOrdersActions->GetClosedOrders\r\n' + error)
                setApiError('Error Retrieving Shipped Orders....');
            });;
    }
}
