import { useEffect, useState, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';

import DataTable, { defaultThemes } from 'react-data-table-component';

import {
    authAtom, quotesAtom,
    openOrdersAtom, closedOrdersAtom,
    responseAtom, orderRespAtom,
    customerAtom, apiErrorAtom,
    openPendingState, shippedPendingState
} from '../_state/state';

import { useQuotesActions } from '../_actions/quotes.actions'
import { useOpenOrdersActions } from '../_actions/openorders.actions'
import { useClosedOrdersActions } from '../_actions/closedorders.actions';

export { Home };

function Home() {

    // using these atoms to share state between componentsS
    // could probably use selectors but this is sufficient for what we need 
    const quotesActions = useQuotesActions();
    const openOrdersActions = useOpenOrdersActions();
    const closedOrdersActions = useClosedOrdersActions();

    const auth = useRecoilValue(authAtom);

    const [quotes, setQuotes] = useRecoilState(quotesAtom);
    const [apiError, setApiError] = useRecoilState(apiErrorAtom);
    const [closedOrders, setClosedOrders] = useRecoilState(closedOrdersAtom);
    const [openOrders, setOpenOrders] = useRecoilState(openOrdersAtom);
    const [orderResp, setOrderRespFlag] = useRecoilState(orderRespAtom);
    const customer = useRecoilValue(customerAtom);

    const setResponseFlag = useSetRecoilState(responseAtom);

    const { register, handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const [pending, setPending] = useState(true);
    const [openPending, setOpenPending] = useRecoilState(openPendingState);
    const [shippedPending, setShippedPending] = useRecoilState(shippedPendingState);

    var qNumber = "";

    useEffect(() => {

        setResponseFlag(false);
        setOrderRespFlag(false);
        setClosedOrders([]);
        setOpenOrders([]);
        setQuotes([]);
        setPending(null);

        setOpenPending(false);
        setShippedPending(false);

        if (setApiError != null)
            setApiError(null);

    }, []);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DataTable Column Definitions
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // quotes column definition
    const quotesColumns = [
        {
            name: 'QNumber',
            selector: row => row.QNumber,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: row => row.SoldName,
            sortable: true,
        },
        {
            name: 'Customer PO',
            selector: row => row.CusPO,
            sortable: true,
        },
        {
            name: 'Customer Number',
            selector: row => row.CustNo,
            right: true,
            sortable: true,
        }
    ];


    // open orders column definition
    const openColumns = [
        {
            name: 'Order No.',
            selector: row => row.OrderNo,
        },
        {
            name: 'Part No.',
            selector: row => row.PartNo,
        },
        {
            name: 'Due Date',
            selector: row => row.DueDate,
        },
        {
            name: 'Order Qty',
            selector: row => row.Qty,
            right: true
        },
        {
            name: 'Open Qty',
            selector: row => row.BackOrderQty,
            right: true
        },
        {
            name: 'MNumber',
            selector: row => row.MNumber,
            right: true
        },
        {
            name: 'Percent Complete',
            selector: row => row.PercentComplete,
            right: true
        }
    ];


    // closed orders column definition
    const closedColumns = [
        {
            name: 'Order No.',
            selector: row => row.OrderNo,
        },
        {
            name: 'Part No.',
            selector: row => row.PartNo,
        },
        {
            name: 'Ship Date',
            selector: row => row.ShipDate,
        },
        {
            name: 'Ship Qty',
            selector: row => row.ShipQty,
        },
        {
            name: 'Carrier',
            selector: row => row.Carrier,
            right: true
        },
        {
            name: 'Tracking Number',
            button: true,
            right: true,
            width: "200px",
            cell: row => (
                <a 
                    href=''
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => openTrackingPage(row.trackingNo, row.Carrier)}
                >
                    {row.trackingNo}
                </a>
        )}
    ];


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DataTable Style Definitions
    //  This is where you can customize the styles - list of overrideable styles
    //  https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.ts
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    const customStyles = {
        header: {
            style: {
                minHeight: '72px',
            },
        },
        headRow: {
            style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
    };


    // conditional style used when selecting and highlighting rows
    const conditionalRowStyles = [
        {
            when: row => row.toggleSelected,
            style: {
                backgroundColor: "lightgray",
                userSelect: "none"
            }
        }
    ];


    const handleRowClicked = row => {
        qNumber = row.QNumber;
        const updatedQuotes = quotes.map(item => {

            if (row.QNumber !== item.QNumber) {
                return {
                    ...item,
                    toggleSelected: false
                };
            }

            return {
                ...item,
                toggleSelected: true
            };
        });

        setQuotes(updatedQuotes);
        getOrders(qNumber);
    };


    // search button clicked 
    function onSubmit({ ponumber }) {
        setPending(true);

        // clear the old orders
        setClosedOrders([]);
        setOpenOrders([]);
        setApiError(null);

        let soldname = customer;

        // retreive the quotes 
        quotesActions.getQuotes(soldname, ponumber, false, auth[0].CustNo).then(x => {
            setPending(false);
        });
    }


    function openTrackingPage(trackingno, carrier) {

        if (carrier.includes('UPS')) {
            window.open('https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=' + trackingno, "_blank");
        }
        else if (carrier.includes('FED')) {
            window.open('https://www.fedex.com/fedextrack/?trknbr=' + trackingno, "_blank");
        }
    }


    // retreive the open and closed orders 
    function getOrders(qNumber) {

        setOrderRespFlag(false);
        setClosedOrders([]);
        setOpenOrders([]);
        setApiError(null);
        setOpenPending(true);
        setShippedPending(true);

        openOrdersActions.getOpenOrders(qNumber, false).then(x => {
            setOpenPending(false);
        });


        closedOrdersActions.getClosedOrders(qNumber, false).then(x => {
            setShippedPending(false);
        });
    }


    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="pt-3 pb-3">Customer Name: {customer}</h2>
                <div className="form-group w-50 mb-2">
                    <label>PO Number</label>
                    <input
                        name="ponumber"
                        type="text"
                        placeholder="Enter Customer PO"
                        {...register('ponumber')}
                        className={`form-control`}
                    />
                </div>
                <div className="mt-3 mb-5">
                    <button
                        disabled={isSubmitting}
                        className="btn btn-primary"
                        style={{ backgroundColor: '#343a40', borderColor: '#fff' }}>
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Search
                    </button>
                    {apiError &&
                        <div className="alert alert-danger mt-3 mb-0">{apiError}</div>
                    }
                </div>
                <h3>Quotes</h3>
                    <div className="mb-5">
                        <DataTable
                            columns={quotesColumns}
                            data={quotes}
                            pagination
                            dense
                            onRowClicked={handleRowClicked}
                            conditionalRowStyles={conditionalRowStyles}
                            customStyles={customStyles}
                            title='Quotes'
                            highlightOnHover
                            progressPending={pending}
                        />
                    </div>

                {orderResp &&
                    <div className="mb-4">
                        <DataTable
                            columns={openColumns}
                            data={openOrders}
                            dense
                            title='Open Orders'
                            customStyles={customStyles}
                            progressPending={openPending}
                        />
                    </div>
                }

                {orderResp &&
                    <div className="mb-5">
                        <DataTable
                            columns={closedColumns}
                            data={closedOrders}
                            dense
                            title='Shipments'
                            customStyles={customStyles}
                            progressPending={shippedPending}
                        />
                    </div>
                }
            </form>
        </div>
    );
}



