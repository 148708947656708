import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useRecoilValue, useSetRecoilState, useRecoilState
    } from 'recoil';

import { authAtom, responseAtom, orderRespAtom, apiErrorAtom, internalAtom, quotesAtom } from '../_state/state';
import { useUserActions } from '../_actions/user.actions';



// TEST CASES
// 1) Invalid login -> Success
// 2) Timeout error
// 3) 500 error
// 4) authenticatino error
// 5) error on quotes
// 6) error on open orders
// 7) error on closed orders

export { Login };

function Login({ history }) {

    const userActions = useUserActions();

    const auth = useRecoilValue(authAtom);
    const apiError = useRecoilValue(apiErrorAtom);
    const setResponseFlag = useSetRecoilState(responseAtom);
    const setOrderRespFlag = useSetRecoilState(orderRespAtom);
    const setInternal = useSetRecoilState(internalAtom);
    const setApiError = useSetRecoilState(apiErrorAtom);
    const [quotes, setQuotes] = useRecoilState(quotesAtom);

    useEffect(() => {
        // redirect to home if already logged in
        if (auth) {
            history.push('/');
        }

        setInternal(true);

        // Set flags that are used to determine if response has come back
        setResponseFlag(false);
        setOrderRespFlag(false);

        // This is here because if there is timeout while retrieving quotes then it errors
        setQuotes([]);

    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build form with useForm() hook
    const { register, handleSubmit, setError, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    // login clicked
    function onSubmit({ username, password }) {
        return userActions.login(username, password);
    }

    return (
        <div className="col-md-6 offset-md-3 mt-5">
            <img src={process.env.PUBLIC_URL + '/TeMpcoEHC.png'} alt='Tempco Electric Heater Corporation' class="center" />
            <h2 style={{color: '#343a40', textAlign: 'center'}}>Order Tracker</h2>
            <div className="card">
                <h4 className="card-header">Login</h4>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>Username</label>
                            <input name="username"  type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.username?.message}</div>
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        <button disabled={isSubmitting} className="btn btn-primary btn-block btn-lg" style={{backgroundColor: '#343a40', borderColor: '#fff'}} >
                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Login
                        </button>
                        {apiError &&
                            <div className="alert alert-danger mt-3 mb-0">{apiError}</div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}
