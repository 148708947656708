import { useEffect, useState, useMemo, setState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';

import {
    apiErrorAtom,
    updateRespAtom,
    usersAtom,
} from '../_state/state';


import { useUserActions } from '../_actions/user.actions';

export { Options };

function Options() {

    const userActions = useUserActions();
    const users = useRecoilValue(usersAtom);

    const { register, handleSubmit, formState } = useForm();
    const [apiError, setApiError] = useRecoilState(apiErrorAtom);
    const [updateResp, setUpdateRespFlag] = useRecoilState(updateRespAtom);




    useEffect(() => {

    }, []);



    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <h3>Update Password</h3>
                <br />

                <div className="form-group w-50">
                    <label>Current Password</label>
                    <input
                        name="password"
                        type="text"
                        placeholder="Enter Password"
                        {...register('password', "required")}
                        className={`form-control`}
                    />
                </div>

                <div className="form-group w-50">
                    <label>New Password</label>
                    <input
                        name="newpassword"
                        type="text"
                        placeholder="Enter New Password"
                        {...register('newpassword')}
                        className={`form-control`}
                    />
                </div>

                <div className="form-group w-50">
                    <label>Re-Enter New Password</label>
                    <input
                        name="newpassword2"
                        type="text"
                        placeholder="Re-Enter New Password"
                        {...register('lastname')}
                        className={`form-control`}
                    />
                </div>                                                                                             

                <br />

                <button
                    className="btn btn-primary"
                    formTarget='_self'
                    name="update"
                >
                    Update
                </button>

                {apiError &&
                    <div className="alert alert-danger mt-3 mb-0">{apiError}</div>
                }

                {(updateResp && !apiError) &&
                    <div className="alert alert-success  mt-3 mb-0">Success</div>
                }
            </form>
        </div>
    );
}



