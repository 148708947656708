    import { useRecoilState, useSetRecoilState } from 'recoil';


// TODO issues that need to be addressed
// -check box for show old password - 
// -add check boxes for add and update and display password accordingly


import { history } from '../_helpers/history';
import { useFetchWrapper } from '../_helpers/fetch-wrapper';
import {
    authAtom, usersAtom, openOrdersAtom, closedOrdersAtom,
    quotesAtom, apiErrorAtom, customerAtom,
    adminAtom, userNameAtom, firstNameAtom,
    lastNameAtom, emailAtom, custNoAtom, changePasswordAtom
} from '../_state/state';

    export { useUserActions };

    function useUserActions () {


        let baseUrl = 'https://ordertracker.tempco.com/ordertracker';
        let loginURI = baseUrl + "/userLogin";
        let addUpdateURI = baseUrl + "/UpdateLogin";
        let getInfoURI = baseUrl + "/UserInfo";
        let getUsersURI = baseUrl + "/Users";
        let getPWURI = baseUrl + "/GetPW";

        const fetchWrapper = useFetchWrapper();

        // using these atoms to share state between components 
        // could probably use selectors but this is sufficient for what we need 
        const setAuth = useSetRecoilState(authAtom);
        const setUsers = useSetRecoilState(usersAtom);
        const setOpenOrders = useSetRecoilState(openOrdersAtom);
        const setClosedOrders = useSetRecoilState(closedOrdersAtom);
        const setQuotes = useSetRecoilState(quotesAtom);
        const setApiError = useSetRecoilState(apiErrorAtom);
        const setCustomer = useSetRecoilState(customerAtom);
        const setAdmin = useSetRecoilState(adminAtom);

        const [email, setEmail] = useRecoilState(emailAtom);
        const [custno, setCustNo] = useRecoilState(custNoAtom);
        const [firstname, setFirstName] = useRecoilState(firstNameAtom);
        const [lastname, setLastName] = useRecoilState(lastNameAtom);
        const [password, setPassword] = useRecoilState(changePasswordAtom);

        return {
            login,
            logout,
            update,
            getusers,
            getinfo, 
            getpw
        }

        // login 
        function login(username, password) {

            return fetchWrapper.post(loginURI, { username, password }).then(user => {
                if (user != null) {
                    if (user.error != null) {
                            console.log('user is not null w/ error' + JSON.stringify(user));
                            setAuth(null);
                            setApiError(user.error);
                        }
                        else {
                            sessionStorage.setItem('user', JSON.stringify(user));
                            setAuth(user);

                            let bAdmin = user[0].IsAdmin;

                            setAdmin(bAdmin);
                            if (bAdmin) {
                                console.log('Admin Logged In');
                            }
                            else {
                                console.log('Non-Admin Logged In');
                            }

                            // storing username
                            setCustomer(username);
                            sessionStorage.setItem('customer', JSON.stringify(username));
                            sessionStorage.setItem('admin', bAdmin);
                        }
                    }
                    else {
                        setAuth(null);
                    }

                    // get return url from location state or default to home page
                    const { from } = history.location.state || { from: { pathname: '/' } };
                    history.push(from);

                }).catch(error => {

                    setAuth(null);
                    console.log('UserActions->login()\r\n' + error)
                    setApiError('Connection Error. Contact Administrator');
                });
        }


        function update(username, password, newuser) {

            return fetchWrapper.post(addUpdateURI, { username, password, newuser, firstname, lastname, email, custno })
                .then(response => {
                    console.log('add/update response received');
                }).catch(error => {
                    console.log('add/update error received');
                });
        }


        function getusers() {
            return fetchWrapper.post(getUsersURI)
                .then(response => {
                    setUsers(response);
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                });
        }


        function getinfo(username) {

            return fetchWrapper.post(getInfoURI, { username})
                .then(response => {
                    setFirstName(response[0]?.FirstName);
                    setLastName(response[0]?.LastName);
                    setEmail(response[0]?.Email);
                    setCustNo(response[0]?.CustNo);
                }).catch(error => {
                    console.log(error);
                });
        }



        function getpw(username) {

            return fetchWrapper.post(getPWURI, { username })
                .then(response => {
                    setPassword(response.Password);
                }).catch(error => {
                    console.log(error);
                });
        }


        //logout
        function logout() {
            // remove user from local storage, set auth state to null and redirect to login page
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('customer');
            sessionStorage.removeItem('admin');
            setAuth(null);
            setOpenOrders([]);
            setClosedOrders([]);
            setQuotes([]);
            history.push('/login');
            setApiError(null);
            setAdmin(null);
            setFirstName(null);
            setLastName(null);
            setEmail(null);
            setCustNo(null); 
        }  
    }
