import { useEffect, useState, useMemo, setState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';

import {
    apiErrorAtom,
    updateRespAtom,
    usersAtom,
    userNameAtom,
    firstNameAtom,
    lastNameAtom,
    emailAtom,
    custNoAtom,
    changePasswordAtom,
    addUserAtom, changeUserNameAtom

} from '../_state/state';

import { useUserActions } from '../_actions/user.actions';

export { Update };

function Update() {

    const userActions = useUserActions();
    const users = useRecoilValue(usersAtom);

    const { register, handleSubmit, formState } = useForm();
    const [apiError, setApiError] = useRecoilState(apiErrorAtom);
    const [updateResp, setUpdateRespFlag] = useRecoilState(updateRespAtom);
    const [firstname, setFirstName] = useRecoilState(firstNameAtom);
    const [lastname, setLastName] = useRecoilState(lastNameAtom);
    const [email, setEmail] = useRecoilState(emailAtom);
    const [custno, setCustNo] = useRecoilState(custNoAtom);
    const [changepassword, setChangePassword] = useRecoilState(changePasswordAtom);
    const [adduser, setIsAddUser] = useRecoilState(addUserAtom);
    const [changeusername, setChangeUserName] = useRecoilState(changeUserNameAtom);
    const [checked, setChecked] = useState(false);


    useEffect(() => {
        setChangeUserName('admin');
        setChecked(false);
        userActions.getusers().then(x => {
            console.log('get users list response received');
        });

        return userActions.getinfo('admin').then(x => {
            console.log('get info response received');
        });
    }, []);


    // search button clicked 
    function onSubmit({ password}) {
        setApiError(null);
        setUpdateRespFlag(false);

        return userActions.update(changeusername, changepassword, adduser).then(x => {
            setUpdateRespFlag(true);
            console.log('update user response received');
        });
    }


    function toggleClicked(button) {
        buttonClicked = button;
    }


    function GetDropDownUserInfo(username){
        setChecked(false);
        setChangePassword('');

        if (!adduser) {
            setChangeUserName(username);

            console.log("user: " + username);
            return userActions.getinfo(username).then(x => {
                console.log('get info response received');
            });
        }
    }


    function GetPassword() {
        setChecked(!checked);

        if (!checked) {
            console.log("user: " + changeusername);
            return userActions.getpw(changeusername).then(x => {
                console.log('get password response received');
            });
        }
        else {
            setChangePassword('');
        }
    }


    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <h3>User Management</h3>
                <br />

                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            value="Add"
                            checked={adduser == true}
                            onChange={e => setIsAddUser(true)}
                        />
                        Add User
                    </label>
                </div>
                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            value="Update"
                            checked={adduser == false}
                            onChange={e => setIsAddUser(false)}
                        />
                        Update User
                    </label>
                </div>

                {(!adduser && users) &&
                    <div className="form-group w-50">
                        <label>User Name</label>
                    <select
                        id='username'
                        name='username'
                        onChange={e => GetDropDownUserInfo(e.target.value)}
                            value={changeusername}
                        >
                        {users.map(user =>
                            <option
                                key={user.username}
                                value={user.username}
                            >{user.username}</option>
                        )}
                        </select>

                    </div>
                }

                {adduser &&
                    <div className="form-group w-50">
                        <label>User Name</label>
                        <input
                            name="usernameinput"
                            type="text"
                            placeholder="Enter Username"
                            className={`form-control`}
                            value={changeusername}
                            onChange={e => setChangeUserName(e.target.value)}
                        />
                    </div>
                }

                <div className="form-group w-50">
                    <label>Password</label>
                    <br />

                    <input
                        name="changepassword"
                        type="text"
                        placeholder="Enter Password"
                        {...register('changepassword')}
                        className={`form-control`}
                        value={changepassword}
                        onChange={e => setChangePassword(e.target.value)}
                    />
                </div>

                {!adduser &&
                    <div>
                        <input
                            name="showpassword"
                            type="checkbox"
                            checked={checked}
                            onChange={e => GetPassword()}
                        />
                        <label>Show Old Password</label>
                    </div>
                }

                <div className="form-group w-50">
                    <label>First Name</label>
                    <input
                        name="firstname"
                        type="text"
                        placeholder="Enter First Name"
                        {...register('firstname')}
                        className={`form-control`}
                        value={firstname}
                        onChange={e => setFirstName(e.target.value)}
                    />
                </div>

                <div className="form-group w-50">
                    <label>Last Name</label>
                    <input
                        name="lastname"
                        type="text"
                        placeholder="Enter Last Name"
                        {...register('lastname')}
                        className={`form-control`}
                        value={lastname}
                        onChange={e => setLastName(e.target.value)}
                    />
                </div>                                                                                             

                <div className="form-group w-50">
                    <label>Email</label>
                    <input
                        name="email"
                        type="text"
                        placeholder="Enter Email"
                        {...register('email')}
                        className={`form-control`}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>

                <div className="form-group w-50">
                    <label>Customer Number</label>
                    <input
                        name="custno"
                        type="text"
                        placeholder="Enter Customer No:"
                        {...register('custno')}
                        className={`form-control`}
                        value={custno}
                        onChange={e => setCustNo(e.target.value)}
                    />
                </div>

                <br />
                <button
                    className="btn btn-primary"
                    formTarget='_self'
                    name="update"
                    onClick={() => toggleClicked("update")}
                >
                    Update
                </button>

                {apiError &&
                    <div className="alert alert-danger mt-3 mb-0">{apiError}</div>
                }

                {(updateResp && !apiError) &&
                    <div className="alert alert-success  mt-3 mb-0">Success</div>
                }
            </form>
        </div>
    );
}



