import React from "react";

function Footer() {
    return (

            <div className='text-center p-3'>
                &copy; {new Date().getFullYear()} Copyright:{' '}
                <a className='text-dark' href='http://tempco.com/'>
                    Tempco Electric Heating Co.
                </a>
            </div>
    );
}

export  { Footer };