import { atom } from 'recoil';

export const apiErrorAtom = atom({
    key: 'apierror',
    default: false,
});

export const closedOrdersAtom = atom({
    key: 'closedorders',
    default: [],
});

export const adminAtom = atom({
    key: 'admin',
    default: JSON.parse(sessionStorage.getItem('admin'))
});

export const authAtom = atom({
    key: 'auth',
    // get initial state from local storage to enable user to stay logged in
    default: JSON.parse(sessionStorage.getItem('user'))
    //default: null
});

export const customerAtom = atom({
    key: 'customer',
    default: JSON.parse(sessionStorage.getItem('customer'))
});

export const internalAtom = atom({
    key: 'internal',
    default: false,
});

export const openOrdersAtom = atom({
    key: 'openorders',
    default: [],
});

export const updateRespAtom = atom({
    key: 'updateresp',
    default: false,
});

export const addUserAtom = atom({
    key: 'adduser',
    default: false,
});

export const changeUserNameAtom = atom({
    key: 'changeusername',
    default: '',
});


export const currentPWAtom = atom({
    key: 'currentpw',
    default: ''
});


export const newPW = atom({
    key: 'newpw',
    default: ''
});

export const orderRespAtom = atom({
    key: 'orderresp',
    default: false,
});



export const quotesAtom = atom({
    key: 'quotes',
    default: [],
});


export const userNameAtom = atom({
    key: 'username',
    default: ''
});


export const custNoAtom = atom({
    key: 'custno',
    default: ''
});


export const firstNameAtom = atom({
    key: 'firstname',
    default: ''
});


export const lastNameAtom = atom({
    key: 'lastname',
    default: ''
});


export const emailAtom = atom({
    key: 'email',
    default: ''
});


export const changePasswordAtom = atom({
    key: 'changepassword',
    default: ''
});


export const usersAtom = atom({
    key: 'users',
    default: null
});

export const responseAtom = atom({
    key: 'response',
    default: false,
});


export const openPendingState = atom({
    key: 'openPendingState',
    default: false,
});

export const shippedPendingState = atom({
    key: 'shippedPendingState',
    default: false,
});