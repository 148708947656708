import { useSetRecoilState } from 'recoil';

import { history } from '../_helpers/history';
import { useFetchWrapper } from '../_helpers/fetch-wrapper';
import { quotesAtom, responseAtom, apiErrorAtom } from '../_state/state';
export { useQuotesActions };

function useQuotesActions() {

    let baseUrl = 'https://ordertracker.tempco.com/ordertracker';
    let quotesURI = baseUrl + "/QuotesByCustNo";


    const fetchWrapper = useFetchWrapper();
    const setQuotes = useSetRecoilState(quotesAtom);
    const setResponseFlag = useSetRecoilState(responseAtom);
    const setApiError = useSetRecoilState(apiErrorAtom);

    return {
        getQuotes
    }

    function getQuotes(soldname, cuspo, internal, custno) {

        setQuotes([]);
        return fetchWrapper.post(quotesURI, { soldname, cuspo, internal, custno }).then(quotes => {
            setResponseFlag(true);
            setQuotes(quotes);
            // get return url from location state or default to home page
            const { from } = history.location.state || { from: { pathname: '/' } };
            history.push(from);
        }
        ).catch(error => {

                setQuotes([]);
                console.log('QuotesActions: getQuotes()\r\n' + error)
                setApiError('Error Retrieving Quotes...');
                setResponseFlag(false);
            });
    }
}
