import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authAtom, adminAtom } from '../_state/state';
import { useUserActions } from '../_actions/user.actions';

function Nav() {
    const auth = useRecoilValue(authAtom);
    const admin = useRecoilValue(adminAtom);
    const userActions = useUserActions();

    // only show nav when logged in
    if (!auth && !admin) return null;

    if (admin) {

        return (
            <nav class="navbar navbar-expand-sm bg-dark navbar-dark justify-content-end">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a href="/"><img class="navbar-brand" src={process.env.PUBLIC_URL + '/TeMpcoEHC.png'} alt='Tempco Electric Heater Corporation' height = '60px' style={{paddingRight: 0}} /> </a>
                    </li>
                    <li class="nav-item active">
                        <h1>Order Tracker</h1>
                    </li>
                </ul>
                <ul class="navbar-nav text-right">
                  <li class="nav-item active">
                    <a class="nav-link" href="/update">Tools</a>
                  </li>
                  <li class="nav-item active">
                    <a onClick={userActions.logout} className="nav-item nav-link">Logout</a>                  
                  </li>
                </ul>
            </nav>
        );
    }
    else {

        return (
            <nav class="navbar navbar-expand-sm bg-dark navbar-dark justify-content-end">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a href="/"><img class="navbar-brand" src={process.env.PUBLIC_URL + '/TeMpcoEHC.png'} alt='Tempco Electric Heater Corporation' height = '60px' style={{paddingRight: 0}} /> </a>
                    </li>
                    <li class="nav-item active">
                        <h1>Order Tracker</h1>
                    </li>
                </ul>
                <ul class="navbar-nav text-right">
                    <li class="nav-item active">
                        <a class="nav-link" href="/options">Options</a>
                    </li>
                    <li class="nav-item active">
                        <a onClick={userActions.logout} className="nav-item nav-link">Logout</a>                  
                    </li>
                </ul>
            </nav>
        );
    }
}

export { Nav };


